<template>
<div class="group-analysis">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="group-analysis-cont">
    <div class="group-analysis-tit">
      <p>各课程练习完成率(%)</p>
    </div>
    <div class="finish-rate" id="finishRate" v-if="finishRate.length"></div>
    <div class="no-data" v-if="!finishRateLoading && !finishRate.length" style="padding: 42px 0;" >
      <img src="../../../assets/images/new_icon/no-data.png" width="255" >
      <p>暂无课程练习完成率...</p>
    </div>
    <div class="group-analysis-tit mt20">
      <p>练习课程统计</p>
    </div>
    <div class="group-list" v-if="groupList.length">
      <div class="group-item" v-for="item in groupList" :key="item.course_id" @click="goDetail(item)">
        <div class="group-item-name">{{ item?.course?.name }}</div>
        <div class="group-item-bot">
          <div>
            <p>查看量：共{{ item.view_count }}次</p>
            <p>答题准确率：{{ item.right_rate }}%</p>
          </div>
          <el-button type="primary" size="small">查看详情</el-button>
        </div>
      </div>
    </div>
    <div class="no-data" v-if="!courseLoading && !groupList.length" style="padding: 42px 0;" >
      <img src="../../../assets/images/new_icon/no-data.png" width="255" >
      <p>暂无学习课程...</p>
    </div>
    <div class="group-analysis-tit">
      <p>练习课程统计</p>
      <el-button type="primary" size="small" v-if="userList.length" @click="exportData">导出</el-button>
    </div>
    <div v-if="userList.length">
      <el-table class="mt20" :data="userList" border size="small">
        <el-table-column label="ID" prop="user_id" width="80"></el-table-column>
        <el-table-column label="学生姓名" prop="name" :show-overflow-tooltip="true">
          <template v-slot="{ row }">
            <span>{{ row?.user?.realname || row?.user?.nickname }}</span>
          </template>
        </el-table-column>
        <el-table-column label="完课率" width="100">
          <template v-slot="{ row }">
            <span>{{ row?.finish_rate }}%</span>
          </template>
        </el-table-column>
        <el-table-column label="登录天数" width="100" prop="login_day_count"></el-table-column>
        <el-table-column label="总试题练习数量" prop="train_count" width="120"></el-table-column>
        <el-table-column label="刷题正确率" width="120" prop="right_rate">
          <template v-slot="{ row }">
            <span>{{ row?.right_rate }}%</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template v-slot="{ row }">
            <el-button size="small" type="primary" @click="goUserDetail(row)">学习详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagin
          style="margin-top: 20px;text-align: end;"
          :pagination="pagination"
          @currentChange="currentChange"
          @sizeChange="sizeChange"
      />
    </div>
    <div class="no-data" v-if="!loading && !userList.length" style="padding: 42px 0;" >
      <img src="../../../assets/images/new_icon/no-data.png" width="255" >
      <p>暂无学生数据...</p>
    </div>
    <div class="group-analysis-tit mt20">
      <p>每日学习人数</p>
    </div>
    <div class="study-day-stat" id="study-day-stat" v-if="studyDay.length"></div>
    <div class="no-data" v-if="!studyDayLoading && !studyDay.length" style="padding: 42px 0;" >
      <img src="../../../assets/images/new_icon/no-data.png" width="255" >
      <p>暂无课程练习完成率...</p>
    </div>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
import Pagin from "@/components/myPagin";
export default {
  name: "groupAnalysis",
  data(){
    return{
      userList:[],
      // 分页器数据
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0
      },
      groupId:'',
      groupList:[],
      loading:true,
      courseLoading:true,
      finishRate:[],
      finishRateLoading:true,
      studyDay:[],
      studyDayLoading:true,
    }
  },
  components:{
    Pagin
  },
  created(){
    this.groupId = this.$route.query.id || '';
  },
  mounted(){
    this.getUserList();
    this.getFinishRate();
    this.getTrainRateStat();
    this.getStudyDayStat();
  },
  methods:{
    getFinishRate(){
      let params = {
        group_id:this.groupId,
      };
      this.finishRateLoading = true;
      this.api.analysis.courseFinishRateStatList(params).then((res)=>{
        this.finishRateLoading = false;
        this.finishRate = res.list || [];
        if(this.finishRate.length){
          this.$nextTick(()=>{
            this.setFinishRateChart(res.list);
          })
        }
      }).catch((err)=>{
        this.finishRateLoading = true;
      })
    },
    setFinishRateChart(data){
      // console.log('setSiteStatByStarChart')

      let chartDom = document.getElementById('finishRate');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      data.forEach((res)=>{
        names.push(res.course.name);
        counts.push(res.finish_rate);
      });

      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show:false,
          // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          //right:0
        },
        grid: {
          left: '40px',
          right: '40px',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          //boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '课程练习完成率',
            type: 'bar',
            stack: 'Total',
            data: counts,
            barMaxWidth:'60'
          },
        ]
      };

      if(data.length){
        option && myChart.setOption(option,true);
      }else{
        this.option && myChart.setOption(this.option,true);
      }

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    },
    getTrainRateStat(){
      let params = {
        group_id:this.groupId,
      };
      this.courseLoading = true;
      this.api.analysis.courseTrainRateStatList(params).then((res)=>{
        this.courseLoading = false;
        this.groupList = res.list;
      }).catch((err)=>{
        this.courseLoading = false;
      })
    },
    getUserList(){
      let params = {
        page: this.pagination.page,
        page_size:this.pagination.pageSize,
        group_id:this.groupId,
      };
      this.loading = true;
      this.api.analysis.courseTrainUserList(params).then((res)=>{
        this.loading = false;
        this.userList = res.list;
        this.pagination.total = +res.count;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    currentChange(page) {
      const { pagination } = this;
      pagination.page = page;
      this.getUserList();
    },
    sizeChange(size) {
      const { pagination } = this;
      pagination.pageSize = size;
      pagination.page = 1;
      this.getUserList();
    },
    getStudyDayStat(){
      let params = {
        group_id:this.groupId,
      }
      this.studyDayLoading = true;
      this.api.analysis.courseStudyDayStat(params).then((res)=>{
        this.studyDayLoading = false;
        this.studyDay = res.list;
        if(this.studyDay.length){
          this.$nextTick(()=>{
            let names = [],counts = [];
            res.list.forEach((item)=>{
              names.push(item.date);
              counts.push(item.user_count);
            });
            this.setStudyDayOption(names,counts)
          })
        }

      }).catch((err)=>{
        this.studyDayLoading = false;
      });
    },
    setStudyDayOption(xAxisData,seriesData){
      var chartDom = document.getElementById('study-day-stat');
      var myChart = this.$echarts.init(chartDom);
      var option;

      option = {
        // title: {
        //   text: '师资注册量统计'
        // },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data:xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: seriesData,
            type: 'line'
          }
        ]
      };

      if(xAxisData.length){
        option && myChart.setOption(option,true);
      }else{
        this.option && myChart.setOption(this.option,true);
      }
      // option && myChart.setOption(option);

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })
    },
    goDetail(data){
      this.$router.push({
        path:'/trainingcamp/group-course-analysis',
        query:{
          groupId:this.groupId,
          courseId:data.course_id
        }
      })
    },
    goUserDetail(data){
      const { searchForm } = this;
      this.$router.push({
        path: "/teacher/course/analysisStudents",
        query: {
          realname: data.user.realname || data.user.nickname,
          groupId:this.groupId
        }
      });
    },
    exportData(){
      let params = {
        group_id:this.groupId,
      };
      this.api.analysis.exportCourseTrainUser(params).then((res)=>{
        let uploadParams = {
          file:res.filepath,
        };
        this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
          util.downLoad(res);
          this.getList();
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.group-analysis{
  padding: 20px;
  .group-analysis-cont{
    padding: 20px;
    background-color: #fff;
    .group-analysis-tit{
      display: flex;
      justify-content: space-between;
      align-items: center;
      >p{
        font-size: 18px;
        font-weight: bold;
      }
    }
    .finish-rate,
    .study-day-stat{
      width: 100%;
      height: 500px;
    }
    .group-list{
      margin-top: 20px;
      .group-item{
        margin-bottom: 20px;
        padding: 20px;
        background-color: #F6F7FA !important;
        border-radius: 6px;
        .group-item-name{
          font-weight: bold;
          font-size: 18px;
          color: #333333;
        }
        .group-item-bot{
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          >div{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color:#666666;
            font-size: 14px;
            >p{
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
  .no-data{
    padding: 100px 0;
    font-size: 14px;
    text-align: center;
    background-color: #FFFFFF;
  }
}
</style>
